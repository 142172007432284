import React from "react";

import { About, Hero, Navbar, Skills } from "./components";

const App = () => (
  <>
    <Navbar />
    <Hero />
    <About />
    {/* <Projects /> */}
    <Skills />
  </>
);

export default App;
